<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        top
        right
        transition="slide-x-reverse-transition"
      >
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>

      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <v-card class="mx-auto mb-12">
              <section-header
                :isCollapsable="false"
                :target="() => this.$refs.contactFieldDetailsContainer"
              >
                <v-container>
                  <v-row>
                    <v-col>Details</v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-container>
              </section-header>
              <v-card-text>
                <v-container fluid ref="contactFieldDetailsContainer">
                  <v-row>
                    <v-col cols="4" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          v-model="contactField.name"
                          :error-messages="errors"
                          label="Name"
                          maxlength="255"
                          :disabled="contactField.isDefault"
                          hint="The name of the contact field."
                          aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="label"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          v-model="contactField.label"
                          :error-messages="errors"
                          label="Label"
                          maxlength="255"
                          hint="The label of the contact field."
                          aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="required"
                        rules="required"
                      >
                        <v-switch
                          v-model="contactField.required"
                          :error-messages="errors"
                          label="Required"
                          :disabled="contactField.isDefault"
                          hint="The required of the contact field."
                          aria-required="true"
                        ></v-switch>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto my-12">
              <section-header
                :target="() => this.$refs.contactFieldsTranslationsContainer"
                :is-collapsable="false"
              >
                <v-container>
                  <v-row>
                    <v-col>Translations</v-col>
                  </v-row>
                </v-container>
              </section-header>
              <v-card-text>
                <v-container fluid ref="contactFieldsTranslationsContainer">
                  <v-row>
                    <v-col cols="4" sm="4" class="mt-3 v-tab">English</v-col>
                    <v-col cols="8" sm="8" class="mt-3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="English"
                        rules="required|max:255"
                      >
                        <v-textarea
                          auto-grow
                          rows="1"
                          required
                          v-model="contactField.label"
                          :error-messages="errors"
                          maxlength="255"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(languageOption, index) in contactField.translations"
                    :key="index"
                  >
                    <v-col cols="4" sm="4" class="mt-3 v-tab">
                      {{ languageOption.label }}
                    </v-col>
                    <v-col cols="8" sm="8" class="mt-3">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="languageOption.label"
                        rules="max:255"
                      >
                        <v-textarea
                          auto-grow
                          rows="1"
                          v-model="languageOption.message"
                          :error-messages="errors"
                          maxlength="255"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { max, required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import SectionHeader from "@admin/components/SectionHeader.vue";

extend("required", {
  ...required,
  message: "Please provide a value.",
});
extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});
setInteractionMode("passive");

export default {
  name: "ContactFieldsForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
      isSubmitting: false,
      screenerId: this.$route.params.screenerId,
      // contactFieldSecondaryTranslations: {},
    };
  },
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapFields("contactFields", {
      contactField: "contactField",
    }),
    ...mapGetters("systemLanguages", {
      secondaryLanguageOptions: "languagesList",
    }),
    ...mapGetters("contactFields", {}),
    ...mapState("contactFields", ["error"]),
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setTitleObject",
      "setBreadcrumbs",
    ]),
    ...mapActions("contactFields", [
      "addContactField",
      "getContactField",
      "clearContactField",
      "clearContactFields",
      "editContactField",
    ]),
    ...mapActions("systemLanguages", {
      getLanguagesList: "getLanguagesList",
    }),

    async submit() {
      if (this.isSubmitting || !(await this.$refs.observer.validate())) {
        return;
      }

      let success = false;
      this.isSubmitting = true;
      if (this.isNew) {
        success = await this.addContactField();
      } else {
        success = await this.editContactField(this.contactField.id);
      }

      if (success !== false) {
        this.clearContactFields();
        const redirect = {
          name: "ContactFieldsIndex",
        };
        await this.$router.push(redirect);
      } else {
        this.snackbar = !!this.error;
      }
      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getLanguagesList();
    this.setBreadcrumbs([
      {
        text: "Home",
        disabled: false,
        href: "/admin",
      },
      {
        text: "Contact Fields",
        disabled: false,
        href: "/admin/contact-fields",
      },
      {
        text: this.id ? "Edit" : "Create",
        disabled: true,
        href: "",
      },
    ]);

    if (this.isNew) {
      this.setTitleObject("Contact Fields (Create)");
    } else {
      this.setTitleObject("Contact Fields (" + this.contactField.label + ")");
      await this.getContactField(this.id).catch(() => {
        this.$router.push({ name: "NotFound" });
      });
    }

    let actionButtons = [];
    actionButtons.push({
      component: "CancelButton",
      attributes: {
        to: "/contact-fields",
      },
      index: 1,
    });
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 0,
    });

    this.setActionButtons(actionButtons);
    this.isLoading = false;
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearContactField();
  },
};
</script>

<style scoped></style>
